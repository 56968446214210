import Vue from 'vue';

/**
 * @typedef {Object} ExportContent
 * @property {string} link Export URL
 */

/**
 * @typedef {{data: ExportContent}} ExportResponse
 */

export default {
    /**
     * @param {any} body
     * @returns {Promise<ExportResponse>}
     */
    exportActions(body = {}) {
        return Vue.prototype.$http.post('miscellaneous/exports/actions', body);
    },
    /**
     * @param {any} body
     * @returns {Promise<ExportResponse>}
     */
    exportUsers(body = {}) {
        return Vue.prototype.$http.post('miscellaneous/exports/users', body);
    },
    /**
     * @param {any} body
     * @returns {Promise<ExportResponse>}
     */
    exportSurveys(body = {}) {
        return Vue.prototype.$http.post('miscellaneous/exports/surveys', body);
    },
    /**
     * @param {number} requirementId
     * @param {string} domain
     * @returns {Promise<string>} URL de redirection DocuSign
     */
    getDocuSignSignUrl(requirementId, domain) {
        return Vue.prototype.$http
            .post('miscellaneous/docusign/getsignurl', {
                user_requirement_id: requirementId,
                domain,
            })
            .then((response) => response.data.redirect_url)
            .catch(() => null);
    },
};
